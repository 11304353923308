import { defineStore } from 'pinia';
import { Notification } from '@arco-design/web-vue';
import '@arco-design/web-vue/es/notification/style/index';
import type { NotificationReturn } from '@arco-design/web-vue/es/notification/interface';
import type { RouteRecordNormalized } from 'vue-router';
import defaultSettings from '@/config/settings.json';
// getMenuList 原始的获取服务端
// import { getMyMenuList } from '@/api/user';
import appClientMenus, {
  createServerMenus1,
  createButtonRole,
} from '@/router/app-menus';
import { AppState } from './types';

const useAppStore = defineStore('app', {
  state: (): AppState => ({
    ...defaultSettings,
    myServerMenu: JSON.parse(localStorage.getItem('menus') as string),
    intro: {
      status: false,
      key: '',
    },
  }),

  getters: {
    appCurrentSetting(state: AppState): AppState {
      return { ...state };
    },
    appDevice(state: AppState) {
      return state.device;
    },
    // 获取服务端数据
    appAsyncMenus(state: AppState): RouteRecordNormalized[] {
      return createServerMenus1(appClientMenus, state.myServerMenu, []);
    },
    userButtonRoles(state: AppState) {
      return createButtonRole(state.myServerMenu);
    },
  },

  actions: {
    toggleIntro(key: any) {
      this.intro.key = key;
      this.intro.status = !this.intro.status;
    },
    // Update app settings
    updateSettings(partial: Partial<AppState>) {
      // @ts-ignore-next-line
      this.$patch(partial);
    },

    // Change theme color
    toggleTheme(dark: boolean) {
      if (dark) {
        this.theme = 'dark';
        document.body.setAttribute('arco-theme', 'dark');
      } else {
        this.theme = 'light';
        document.body.removeAttribute('arco-theme');
      }
    },
    toggleDevice(device: string) {
      this.device = device;
    },
    toggleMenu(value: boolean) {
      this.hideMenu = value;
    },
    async fetchServerMenuConfig() {
      let notifyInstance: NotificationReturn | null = null;
      try {
        notifyInstance = Notification.info({
          id: 'menuNotice', // Keep the instance id the same
          content: 'loading',
          closable: true,
        });
        // const { data } = await getMenuList();
        // this.serverMenu = data;
        // @ts-ignore
        // const {
        //   data: { data },
        // } = await getMyMenuList();
        this.myServerMenu = JSON.parse(localStorage.getItem('menus'));
        notifyInstance = Notification.success({
          id: 'menuNotice',
          content: '登录成功',
          closable: true,
        });
      } catch (error) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        notifyInstance = Notification.error({
          id: 'menuNotice',
          content: 'error',
          closable: true,
        });
      }
    },
    clearServerMenu() {
      this.serverMenu = [];
    },
  },
});

export default useAppStore;
