import type { Router } from 'vue-router';
import { useAppStore } from '@/store';
import { setRouteEmitter } from '@/utils/route-listener';
import setupUserLoginInfoGuard from './userLoginInfo';
import setupPermissionGuard from './permission';

let appStore = null;

function setupPageGuard(router: Router) {
  router.beforeEach(async (to) => {
    // emit route change
    setRouteEmitter(to);
  });
}

// 在路由中读取本地配置并写入到store中。
function readLocalAppSetting(router: Router) {
  router.beforeEach((to, from, next) => {
    appStore = useAppStore();
    const localAppSetting = localStorage.getItem('appSetting');
    if (localAppSetting) {
      const appSetting = JSON.parse(localAppSetting);
      appStore.$state.menu = appSetting.menu;
      appStore.$state.topMenu = appSetting.topMenu;
      appStore.$state.footer = appSetting.footer;
      appStore.$state.tabBar = appSetting.tabBar;
    }
    next();
  });
}

export default function createRouteGuard(router: Router) {
  setupPageGuard(router);
  readLocalAppSetting(router);
  setupUserLoginInfoGuard(router);
  setupPermissionGuard(router);
}
