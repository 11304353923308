import { defineStore } from 'pinia';
import http from 'axios';

const useOrderStore = defineStore('useOrderStore', {
  state() {
    return {};
  },
  actions: {
    // 创建订单
    async createdOrder(params: any) {
      const data = await http.post('/order-service/order', params);
      return data;
    },
    // 查询可购买的套餐
    async getSetmealList(params: any) {
      const data = await http.get('/order-service/of-order-goods/info', {
        params,
      });
      return data;
    },
    // 查询订单列表
    async getOrderListData(params: any) {
      const data = await http.get(`/order-service/order`, { params });
      return data;
    },
    // 编辑学员基本信息
    async editOrderBasicInfo(params: any) {
      const data = await http.put(
        `/order-service/order/${params.code}`,
        params.orderSimplerUpdate
      );
      return data;
    },
    // 查询订单轨迹列表
    async getOrdertrackList(params: any) {
      const data = await http.get(`/order-service/track`, { params });
      return data;
    },
    // 删除订单
    async deleteOrder(params: any) {
      const data = await http.put(`/order-service/order/${params.code}/del`);
      return data;
    },
    // 置为失效订单
    async invalidOrder(params: any) {
      const data = await http.put(
        `/order-service/order/${params.code}/invalid`
      );
      return data;
    },
    // 查找订单列表
    async getOrderQueryList(params: any) {
      const data = await http.get(`/order-service/order/search`, { params });
      return data;
    },
    // 根据订单编号查询详情
    async getOrderDetail(params: any) {
      const data = await http.get(
        `/order-service/order/${params.code}/details`
      );
      return data;
    },
    // 查询退款列表
    async getRefundList(params: any) {
      const data = await http.get(`/order-service/order/${params.code}/refund`);
      return data;
    },
    // 获取支付列表
    async getPayList(params: any) {
      const data = await http.get(`/order-service/order/${params.code}/pay`);
      return data;
    },
    // 根据订单code查询当前订单展示的按钮
    async getOrderOperateAuth(params: any) {
      const data = await http.get(
        `/order-service/order/${params.code}/buttons`
      );
      return data;
    },
    // 绑定支付
    async getBindPay(params: any) {
      const data = await http.post(
        `/order-service/order/${params.code}/bind-pay`,
        params.payObj
      );
      return data;
    },
    // 创建尾款订单
    async createSonOrder(params: any) {
      const data = await http.post(
        `/order-service/order/${params.code}/partial-pay`,
        params.payObj
      );
      return data;
    },
    // 根据订单code计算还剩多少尾款金额
    async getSonOrderPrice(params: any) {
      const data = await http.get(
        `/order-service/order/${params.code}/son-price`
      );
      return data;
    },
    // 升级套餐
    async upgradeOrder(params: any) {
      const data = await http.post(
        `/order-service/order/${params.code}/upgrade`,
        params.payObj
      );
      return data;
    },
    // 根据支付编号查询详情
    async getPaymentDetail(params: any) {
      const data = await http.get(`/order-service/payment/${params.payCode}`);
      return data;
    },
    // 编辑支付信息
    async editPaymentInfo(params: any) {
      const data = await http.put(
        `/order-service/payment/${params.payCode}`,
        params.payObj
      );
      return data;
    },
    // 退款列表
    async getRefundListData(params: any) {
      const data = await http.get(`/order-service/refunds`, { params });
      return data;
    },
    // 确认退款
    async confirmRefund(params: any) {
      const data = await http.put(
        `/order-service/refunds/${params.code}/confirm`,
        params.refundObj
      );
      return data;
    },
    // 审核退款
    async verifyRefund(params: any) {
      const data = await http.put(
        `/order-service/refunds/${params.code}/verify`,
        params.refundObj
      );
      return data;
    },
    // 退款无效
    async invalidRefund(params: any) {
      const data = await http.put(
        `/order-service/refunds/${params.code}/invalid`,
        params.refundObj
      );
      return data;
    },
    // 修改退款
    async editRefundInfo(params: any) {
      const data = await http.put(
        `/order-service/refunds/${params.code}/modify`,
        params.refundObj
      );
      return data;
    },
    // 根据订单code计算还已缴费金额
    async getUpgradePrice(params: any) {
      const data = await http.get(
        `/order-service/order/${params.code}/pay-price`
      );
      return data;
    },
    // 申请退款
    async refundOrder(params: any) {
      const data = await http.post(
        `/order-service/order/${params.code}/refund`,
        params.refundObj
      );
      return data;
    },
    // 根据退款编号查询退款
    async getRufundDetail(params: any) {
      const data = await http.get(
        `/order-service/refunds/${params.code}/refund`
      );
      return data;
    },
    // 确认订单支付
    async confirmOrderPay(params: any) {
      const data = await http.put(
        `/order-service/payment/${params.code}/confirm`,
        params.payObj
      );
      return data;
    },
    // 审核订单支付
    async verifyOrderPay(params: any) {
      const data = await http.put(
        `/order-service/payment/${params.code}/verify`,
        params.payObj
      );
      return data;
    },
    async getRefundPrice(params: any) {
      const data = await http.get(
        `/order-service/order/${params.code}/refund-price`
      );
      return data;
    },
    // 查询协议签署状态
    async getProtocolStatus(params: any,type:any) {
      const data = await http.get(
        `/order-service/order/${params.code}/whether-sign?type=${type}`
      );
      return data;
    },
    // 下单前校验量账号
    async checkFlowAccount(params: any) {
      const data = await http.get(`/customer-service/customers/adviser`, {
        params,
      });
      return data;
    },
    // 获取微信支付appid
    async getPaymentWxAppId() {
      const data = await http.get(`/order-service/payment/wx/appId`);
      return data;
    },
    // 查询协议列表
    async getProtocolList(params: any) {
      const data = await http.get('/order-service/agreement', { params });
      return data;
    },
    // 创建协议
    async createProtocol(params: any) {
      const data = await http.post('/order-service/agreement', params);
      return data;
    },
    // 删除协议
    async deleteProtocol(params: any) {
      const data = await http.put(`/order-service/agreement/${params.id}/del`);
      return data;
    },
    // 查询协议详情
    async getProtocolDetail(params: any) {
      const data = await http.get(`/order-service/agreement/${params.id}`);
      return data;
    },
    // 修改协议
    async updateProtocol(params: any, id: any) {
      const data = await http.put(`/order-service/agreement/${id}`, params);
      return data;
    },
    async getNewPayCode(params: any) {
      const data = await http.get(
        `/order-service/order/${params.orderCode}/new-pay-code`
      );
      return data;
    },
    // 启用协议
    async openProtocol(params: any) {
      const data = await http.put(
        `/order-service/agreement/${params.id}/enable?type=${params.type}`
      );
      return data;
    },
    // 禁用协议
    async closeProtocol(params: any) {
      const data = await http.put(
        `/order-service/agreement/${params.id}/disable`
      );
      return data;
    },
    // 根据订单code查询量账号
    async getRefundFlowAccount(params: any) {
      const data = await http.get(
        `/order-service/order/${params.code}/flow-account`
      );
      return data;
    },
    // 订单列表导出
    async expoerOrderListData(params: any) {
      const data = await http.get(`/order-service/export/order`, {
        params,
        responseType: 'blob',
      });
      return data;
    },
    // 查看当前学管师
    async checkCurrentLearnManager(params: any) {
      const data = await http.get(
        `/xuefu-service/order/${params.code}/learnManager`
      );
      return data;
    },
    // 获取支付列表数据
    async getPaymentListData(params: any) {
      const data = await http.get(`/order-service/payment`, { params });
      return data;
    },
    // 根据订单编号查询手机号
    async getUserPhoneByPhone(params: any) {
      const data = await http.get(`/order-service/order/${params.code}/phone`);
      return data;
    },
  },
  getters: {},
});

export default useOrderStore;
