import { defineStore } from 'pinia';
import http from 'axios';

const useSettingsStore = defineStore({
  id: 'settings',
  state: () => ({}),
  actions: {
    async getTags(params: any) {
      const data = await http.get('/customer-service/tags', {
        params,
      });
      return data;
    },
    async addTag(form: any) {
      const data = await http.post('/customer-service/tags', form);
      return data;
    },
    async modifyTag(form: any) {
      const data = await http.put('/customer-service/tags', form);
      return data;
    },
  },
});
export default useSettingsStore;
