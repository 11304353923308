import { DEFAULT_LAYOUT } from '../base';
import { AppRouteRecordRaw } from '../types';

const FLOWGROUP: AppRouteRecordRaw = {
  path: '/flow-service/group',
  name: 'group',
  component: DEFAULT_LAYOUT,
  meta: {
    name: '量配置',
    locale: 'menu.flow',
    requiresAuth: true,
    icon: 'icon-interaction',
  },
  children: [
    {
      path: 'input-flow',
      name: 'inputFlow',
      component: () => import('@/views/course-service/index.vue'),
      meta: {
        name: '推量组',
        locale: 'menu.flow.trialList',
        requiresAuth: true,
        roles: ['*'],
      },
    },
    {
      path: 'reception-flow',
      name: 'receptionFlow',
      component: () => import('@/views/course-service/index.vue'),
      meta: {
        name: '接量组',
        locale: 'menu.flow.reception',
        requiresAuth: true,
        roles: ['*'],
      },
    },
  ],
};

export default FLOWGROUP;
