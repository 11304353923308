import { computed, createApp } from 'vue';
import ArcoVueIcon from '@arco-design/web-vue/es/icon';
import globalComponents from '@/components';
import moment from 'moment';
import globalFormatter from '@/utils/globalFormatter';
import QRCode from 'qrcode';
import { registerMicroApps, start, runAfterFirstMounted } from 'qiankun';
import MicroApp from '@/config/app';
import router from './router';
import store from './store';
import i18n from './locale';
import directive from './directive';
import App from './App.vue';
import '@/assets/style/global.less';
import '@/api/interceptor';
import { type } from './router/routes/types';

let app = null;
const instance = () => {
  app = createApp(App);
  app.config.globalProperties.$moment = moment;
  app.config.globalProperties.$globalFormatter = globalFormatter;
  app.config.globalProperties.$QRCode = QRCode;

  app.use(ArcoVueIcon);
  app.use(store);
  app.use(router);
  app.use(i18n);
  app.use(globalComponents);
  app.use(directive);

  const btnList: any[] = [];
  function redu(menus: any) {
    if (menus && menus.length > 0) {
      menus.forEach((item: any) => {
        if (item.type === 'F') {
          btnList.push(item.id);
        }
        if (item.children) {
          redu(item.children);
        }
      });
    }
    return btnList;
  }
  app.directive('permission', {
    mounted(el, binding) {
      // 绑定的值
      const { value } = binding;
      if (value) {
        // @ts-ignore
        const menu = JSON.parse(localStorage.getItem('menus'));
        //  所有的权限id集合
        const buttonList = redu(menu);
        const hasPermission = buttonList.some((item) => {
          // eslint-disable-next-line eqeqeq
          return item === value;
        });
        //  么得权限
        if (!hasPermission) {
          // eslint-disable-next-line no-unused-expressions
          el.parentNode && el.parentNode.removeChild(el);
        }
      }
    },
  });

  app.mount('#app-container');
};

MicroApp().then((res: any) => {
  instance();
  registerMicroApps(
    // [
    //   {
    //     name: 'teach-service',
    //     entry: 'http://localhost:5176',
    //     container: '#app-wrap',
    //     activeRule: '/teach-service',
    //   },
    //   {
    //     name: 'micro1-service',
    //     entry: 'http://localhost:9999',
    //     container: '#app-wrap',
    //     activeRule: '/micro1-service',
    //   },
    // {
    //   name: 'flow-service',
    //   entry: 'http://localhost:5177',
    //   container: '#app-wrap',
    //   activeRule: '/flow-service',
    // },
    // {
    //   name: 'statistics-service',
    //   entry: 'http://localhost:5178',
    //   container: '#app-wrap',
    //   activeRule: '/statistics-service',
    // },
    // ],
    // [
    //   {
    //     name: 'teach-service',
    //     entry: 'http://teach.safe.beer',
    //     activeRule: '/teach-service',
    //     container: '#app-wrap',
    //   },
    //   {
    //     name: 'flow-service',
    //     entry: 'http://flow.safe.beer',
    //     activeRule: '/flow-service',
    //     container: '#app-wrap',
    //   },
    //   {
    //     name: 'statistics-service',
    //     entry: 'http://statistics.safe.beer',
    //     activeRule: '/statistics-service',
    //     container: '#app-wrap',
    //   },
    // ],
    res,
    {
      // @ts-ignore
      beforeLoad: (appLoad: any) => {
        console.log(
          '[LifeCycle] beforeLoad %c%s',
          'color: green;',
          appLoad.name
        );
        // eslint-disable-next-line no-underscore-dangle
        window.__POWERED_BY_QIANKUN__ = true;
      },
      beforeMount: [
        // @ts-ignore
        (appLoad: any) => {
          console.log(
            '[LifeCycle] before mount %c%s',
            'color: green;',
            appLoad.name
          );
          // eslint-disable-next-line no-underscore-dangle
          window.__POWERED_BY_QIANKUN__ = true;
        },
      ],
      afterMount: [
        // @ts-ignore
        (appLoad: any) => {
          console.log(
            '[LifeCycle] after mount %c%s',
            'color: green;',
            appLoad.name
          );
        },
      ],
      afterUnmount: [
        // @ts-ignore
        (appLoad: any) => {
          console.log(
            '[LifeCycle] after unmount %c%s',
            'color: green;',
            appLoad.name
          );
        },
      ],
    }
  );
  // setDefaultMountApp('/sub-vue');
  // 启动应用
  setTimeout(() => {
    start();
  }, 600);
  runAfterFirstMounted(() => {
    console.log('runAfterFirstMounted:');
  });
});
