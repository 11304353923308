import moment from 'moment';
import BigNumber from 'bignumber.js';

const globalFormatter = {
  /**
   * val 数值 multiple相乘倍数一般为100 type 传入的数值的单位
   * plus 相加
   * minus 相减
   * div 相除
   * times 相乘
   * pow 幂
   * mod 取模
   * squareRoot 平方根
   * cent 以分为单位用于接口传参 yuan 以元为单位用于展示
   */
  bigNumber: (type: any, val: any, data: any) => {
    let num = '';
    if (!val) {
      val = 0;
    }
    if (!data) {
      data = 0;
    }
    // 相乘
    if (type === 'times') {
      num = new BigNumber(val).times(data).toString();
    }
    // 相除
    if (type === 'div') {
      num = new BigNumber(val).div(data).toString();
    }
    // 相加
    if (type === 'plus') {
      num = new BigNumber(val).plus(data).toString();
    }
    // 相减
    if (type === 'minus') {
      num = new BigNumber(val).minus(data).toString();
    }
    return Number(num);
  },
  // 订单状态
  OrderStatusEnum(a: any) {
    let value = '';
    switch (a) {
      case 'UNPAID':
        value = '待付款';
        break;
      case 'PARTIAL_PAY':
        value = '部分付款';
        break;
      case 'FULL_PAY':
        value = '已付全款';
        break;
      case 'REFUNDED':
        value = '已退款';
        break;
      case 'MANUAL_INVALID':
        value = '手动失效';
        break;
      case 'AUTOMATIC_INVALID':
        value = '自动失效';
        break;
      default:
        value = '-';
    }
    return value;
  },
  // 订单类型
  orderType(a: any) {
    let value = '';
    switch (a) {
      case 'NEW':
        value = '新单';
        break;
      case 'UPGRADE':
        value = '升级';
        break;
      default:
        value = '-';
    }
    return value;
  },
  // 支付方式
  PayChannelEnum(a: any) {
    let value = '';
    switch (a) {
      case 'ZFB':
        value = '支付宝';
        break;
      case 'WX':
        value = '微信';
        break;
      case 'OTHER':
        value = '其他';
        break;
      default:
        value = '-';
    }
    return value;
  },
  // 订单付款方式
  PayWayEnum(a: any) {
    let value = '';
    switch (a) {
      case 'ONLINE':
        value = '线上';
        break;
      case ' OFFLINE':
        value = '线下';
        break;
      default:
        value = '-';
    }
    return value;
  },
  formatterDate(row: any) {
    return row ? moment(Number(row)).format('YYYY-MM-DD HH:mm:ss') : '-';
  },
  formatterDateMinute(row: any) {
    return row ? moment(Number(row)).format('YYYY-MM-DD HH:mm') : '-';
  },
  dealFormatterDate(row: any) {
    return row ? moment(Number(row)).format('YYYY-MM-DD') : '-';
  },
  // 退款状态
  refundStatus(a: any) {
    let value = '';
    switch (a) {
      case 'WAIT_CONFIRM':
        value = '待确认';
        break;
      case ' CONFIRM_REBUT':
        value = '确认驳回';
        break;
      case 'WAIT_VERIFY':
        value = '待审核';
        break;
      case 'VERIFY_REBUT':
        value = '审核驳回';
        break;
      case 'PASS':
        value = '审核通过';
        break;
      case 'INVALID':
        value = '无效';
        break;
      default:
        value = '-';
    }
    return value;
  },
  // 支付状态
  payStatus(a: any) {
    let value = '';
    switch (a) {
      case 'UNPAID':
        value = '待支付';
        break;
      case 'WAIT_VERIFY':
        value = '待审核';
        break;
      case 'VERIFY_REBUT':
        value = '审核驳回';
        break;
      case 'PASS':
        value = '已完成';
        break;
      case 'INVALID':
        value = '无效';
        break;
      default:
        value = '-';
    }
    return value;
  },
};

export default globalFormatter;
