import { DEFAULT_LAYOUT } from '../base';
import { AppRouteRecordRaw } from '../types';

const Ocean: AppRouteRecordRaw = {
  path: '/teach-service',
  name: 'teach',
  component: DEFAULT_LAYOUT,
  meta: {
    name: '课程管理',
    locale: 'menu.course.manage',
    requiresAuth: true,
    icon: 'icon-book',
    // order: 0,
  },
  children: [
    {
      path: 'trial/list',
      name: 'courses',
      component: () => import('@/views/course-service/index.vue'),
      meta: {
        name: '试学课列表',
        locale: 'menu.course.trialList',
        requiresAuth: true,
        roles: ['*'],
      },
    },
    {
      path: 'trial/curriculum-list',
      name: 'TrialCurriculumList',
      component: () => import('@/views/course-service/index.vue'),
      meta: {
        name: '章节列表页面',
        locale: 'menu.course.curriculum',
        hideInMenu: true,
        requiresAuth: true,
        roles: ['*'],
      },
    },
    {
      path: 'trial/calendar',
      name: 'calendar',
      component: () => import('@/views/course-service/index.vue'),
      meta: {
        name: '排课日历',
        locale: 'menu.course.calendar',
        requiresAuth: true,
        roles: ['*'],
      },
    },
    {
      path: 'category/list',
      name: 'CategoryList',
      component: () => import('@/views/course-service/index.vue'),
      meta: {
        name: '学院列表',
        locale: 'menu.course.category',
        requiresAuth: true,
        roles: ['*'],
      },
    },
    {
      path: 'formal/list',
      name: 'FormalCourseList',
      component: () => import('@/views/course-service/index.vue'),
      meta: {
        name: '正式课列表',
        locale: 'menu.course.formalList',
        requiresAuth: true,
        roles: ['*'],
      },
    },
    {
      path: 'formal/stages',
      name: 'StagesManager',
      component: () => import('@/views/course-service/index.vue'),
      meta: {
        name: '排期管理',
        locale: 'menu.course.stages',
        hideInMenu: true,
        requiresAuth: true,
        roles: ['*'],
      },
    },
    {
      path: 'formal/channels',
      name: 'ChannelManager',
      component: () => import('@/views/course-service/index.vue'),
      meta: {
        name: '正式课-章节列表',
        locale: 'menu.course.channels',
        hideInMenu: true,
        requiresAuth: true,
        roles: ['*'],
      },
    },
  ],
};

export default Ocean;
