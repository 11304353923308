import axios from 'axios';
import type { TableData } from '@arco-design/web-vue/es/table/interface';

export interface ContentDataRecord {
  x: string;
  y: number;
}

export function queryContentData() {
  return axios.get<ContentDataRecord[]>('/api/content-data');
}

export interface PopularRecord {
  key: number;
  clickNumber: string;
  title: string;
  increases: number;
}

export interface TodayOrderAndSales {
  dealNum: number;
  salesVolume: number;
}

export interface TodayInputAndReception {
  inputNum: number;
  reception: number;
}

export interface InputData {
  label: string;
  value: number;
}
export interface InputSeven {
  datas: InputData[];
  sum: number;
}

export interface CanalData {
  label: string;
  value: number;
}
export interface GoodsRanking {
  count: number;
  name: string;
}

export function queryPopularList(params: { type: string }) {
  return axios.get<TableData[]>('/api/popular/list', { params });
}

// 查询7天、月度下单统计数据
export function queryStageCountPrice() {
  return axios.get('/order-service/order-data/stage/count-price');
}
// 查询商品统计数据
export function queryGoodsRanking() {
  return axios.get<GoodsRanking[]>('/order-service/order-data/goods/ranking');
}

// 今日成单数和今日销售额
export function queryTodayOrderAndSales() {
  return axios.get<TodayOrderAndSales>(
    '/customer-service/customers/count/deal'
  );
}

// 今日入量数和接量数
export function queryInputCustomerAndReception() {
  return axios.get<TodayInputAndReception>(
    '/customer-service/customers/count/input'
  );
}

// 七天入量总趋势
export function queryInputSeven() {
  return axios.get<InputSeven>('/customer-service/customers/count/input-seven');
}

// 渠道统计
export function queryCanalCount() {
  return axios.get<CanalData[]>('/customer-service/customers/count/work');
}
