import { DEFAULT_LAYOUT } from '../base';
import { AppRouteRecordRaw } from '../types';

const Ocean: AppRouteRecordRaw = {
  path: '/order',
  name: 'Order',
  component: DEFAULT_LAYOUT,
  meta: {
    name: '订单',
    locale: 'menu.order',
    requiresAuth: true,
    icon: 'icon-file',
    order: 0,
  },
  children: [
    {
      path: 'list',
      name: 'OrderList',
      component: () => import('@/views/order/list/index.vue'),
      meta: {
        name: '订单列表',
        locale: 'menu.order.list',
        requiresAuth: true,
        roles: ['*'],
      },
    },
    {
      path: 'add',
      name: 'OrderAdd',
      component: () => import('@/views/order/add/index.vue'),
      meta: {
        name: '创建订单',
        locale: 'menu.order.add',
        requiresAuth: true,
        roles: ['*'],
      },
    },
    {
      path: 'query',
      name: 'OrderQuery',
      component: () => import('@/views/order/order-query/index.vue'),
      meta: {
        name: '查找订单',
        locale: 'menu.order.query',
        requiresAuth: true,
        roles: ['*'],
      },
    },
    {
      path: '/order/refund',
      name: 'OrderRefund',
      component: () => import('@/views/order/refund/index.vue'),
      meta: {
        name: '退款列表',
        locale: 'menu.order.refund',
        requiresAuth: true,
        roles: ['*'],
      },
    },
    {
      path: 'payment',
      name: 'Payment',
      component: () => import('@/views/order/payment/index.vue'),
      meta: {
        name: '支付列表',
        locale: 'menu.order.payment',
        requiresAuth: true,
        roles: ['*'],
      },
    },
  ],
};

export default Ocean;
