import { DirectiveBinding } from 'vue';
import { useAppStore } from '@/store';
/**
 * 根据权限id、name 检查是否拥有按钮权限
 * 页面使用  v-permissionm="{ id: 7, name: '部门/员工查看' }"
 * @param el
 * @param binding {id,1,name:'新建员工'}
 */
function checkButtonPermission(el: HTMLElement, binding: DirectiveBinding) {
  const { value } = binding;
  const appStore = useAppStore();
  const { userButtonRoles } = appStore;
  const userRoles = userButtonRoles.find(
    (ub) => ub.id === value.id && ub.name === value.name
  );
  if (!userRoles && el.parentNode) {
    el.parentNode.removeChild(el);
  }
}

export default {
  // 页面首次挂载生效
  mounted(el: HTMLElement, binding: DirectiveBinding) {
    checkButtonPermission(el, binding);
  },
  // updated(el: HTMLElement, binding: DirectiveBinding) {
  //   checkButtonPermission(el, binding);
  // },
};
