import { defineStore } from 'pinia';
import http from 'axios';

const useFrameworkStore = defineStore({
  id: 'framework',
  state: () => ({}),
  actions: {
    // 获取角色列表
    async getRoles(params?: any) {
      const data = await http.get('/user-service/role/list', {
        params,
      });
      return data;
    },
    // 获取部门列表
    // async getDepts(params?: any) {
    //   const data = await http.get('/user-service/dept/tree', {
    //     params,
    //   });
    //   return data;
    // },
    async getDepts(params?: any) {
      const data = await http.get('/staff-service/user/deptTree', {
        params,
      });
      return data;
    },
    // 获取部门列表
    async getDeptsAll() {
      const data = await http.get('/user-service/dept/treeAll');
      return data;
    },
    // 编辑角色
    async editRole(params: any) {
      const data = await http.post(`/user-service/role/update`, params);
      return data;
    },
    // 获取钉钉架构列表
    async getDingTalkDeptList(id: any = 1) {
      const data = await http.get(`/user-service/dingtalk/dept/list`, {
        params: {
          parentId: id,
        },
      });
      return data;
    },
    // 根据钉钉部门ID获取用户信息
    async getDingTalkUserList(params: any) {
      const data = await http.get(
        `/user-service/dingtalk/user/list/${params.deptId}`,
        {
          params,
        }
      );
      return data;
    },
    // 添加员工
    async addUser(params: any) {
      const data = await http.post('/user-service/user/add', params);
      return data;
    },
    // 添加部门
    async addDept(params: any) {
      const data = await http.post('/user-service/dept/add', params);
      return data;
    },
    // 获取部门详情
    async getDeptDetail(id: any) {
      const data = await http.get(`/user-service/dept/${id}`);
      return data;
    },
    // 编辑部门
    async editDept(params: any) {
      const data = await http.post('/user-service/dept/update', params);
      return data;
    },
    // 删除部门
    async deleteDept(id: any) {
      const data = await http.post(`/user-service/dept/delete/${id}`);
      return data;
    },
    async userRelationRole(params: any) {
      const data = await http.post(
        `/user-service/user/relation/role/${params.roleId}`,
        params.userIds
      );
      return data;
    },
    async deptRelationUser(params: any) {
      const data = await http.post(
        `/user-service/dept/relation/${params.deptIds}`,
        params.dept
      );
      return data;
    },
    async unRelationUserAndRole(params: any) {
      const data = await http.post(
        `/user-service/user/unrelation/role`,
        params
      );
      return data;
    },
    // 根据ID员工详情
    async getUserDetail(id: any) {
      const data = await http.get(`/user-service/user/${id}`);
      return data;
    },
    // 更新员工状态
    async updateUserStatus(params: any) {
      const data = await http.post(
        `/user-service/user/status/update/${params.userId}`,
        params
      );
      return data;
    },
    // 获取工具号详情
    async getToolAccountDetail(id: any) {
      const data = await http.get(`/user-service/toolaccount/${id}`);
      return data;
    },
    // 获取用户详情
    async getUserDetailById(id: any) {
      const data = await http.get(`/user-service/user/${id}`);
      return data;
    },
    // 获取支付渠道列表
    async getPayChannelList() {
      const data = await http.get('/user-service/enterprises/paymentChannel');
      return data;
    },
    // 获取支付渠道列表
    async getPayChannelDetail(id: any) {
      const data = await http.get(
        `/user-service/enterprises/paymentChannel/info/${id}`
      );
      return data;
    },
    // 编辑支付渠道
    async editPayChannel(params: any) {
      const data = await http.put(
        `/user-service/enterprises/paymentChannel/update`,
        params
      );
      return data;
    },
    // 更改支付渠道状态
    async updatePayChannelStatus(params: any) {
      const data = await http.post(
        `/user-service/enterprises/paymentChannel/status`,
        params
      );
      return data;
    },
    // 添加支付渠道
    async addPayChannel(params: any) {
      const data = await http.post(
        `/user-service/enterprises/paymentChannel/add`,
        params
      );
      return data;
    },
    // 添加外部员工
    async addUserExtra(params: any) {
      const data = await http.post(`/user-service/user/add/outside`, params);
      return data;
    },
    // 查看客户端配置
    async getClientConf() {
      const {data} = await http.get(`/user-service/enterprises/client`);
      return data;
    },
    // 保存客户端配置
    async addClientConf(params: any) {
      const data = await http.post(`/user-service/enterprises/client`, params);
      return data;
    },
    // 修改客户端配置
    async updateClientConf(id:any,params: any) {
      const data = await http.put(`/user-service/enterprises/client/${id}`, params);
      return data;
    },
  },
});
export default useFrameworkStore;
