import { defineStore } from 'pinia';
import http from 'axios';

const userGoodsStore = defineStore('userGoodsStore', {
  state() {
    return {};
  },
  actions: {
    // 查询商品列表
    async getGoodsList(params: object) {
      const data = await http.get('/goods-service/goods', { params });
      return data;
    },
    // 新增商品
    async addGoods(params: object) {
      const data = await http.post('/goods-service/goods', params);
      return data;
    },
    // 删除商品
    async delGoods(params: { id: string }) {
      const data = await http.put(`/goods-service/goods/${params.id}/del`);
      return data;
    },
    // 修改商品状态
    async editGoodsStatus(params: { id: string }) {
      const data = await http.put(`/goods-service/goods/${params.id}/status`);
      return data;
    },
    // 查询商品详情
    async getGoodsDetail(params: { id: string }) {
      const data = await http.get(`/goods-service/goods/${params.id}`);
      return data;
    },
    // 修改商品
    async editGoodsDetail(params: { id: string; obj: any }) {
      const data = await http.put(
        `/goods-service/goods/${params.id}`,
        params.obj
      );
      return data;
    },
    // 查询正式课列表
    async getFormalCourseData(params: object) {
      const data = await http.get('/formal-course-service/courses', { params });
      return data;
    },
    // 编辑商品关联的课程信息
    async editRelevanceCourse(params: any) {
      const data = await http.put(
        `/goods-service/goods/${params?.id}/course-ref`,
        params.goodsCourseUpdates
      );
      return data;
    },
    async getRelevanceCourseList(params: any) {
      const data = await http.get(
        `/goods-service/goods/${params?.id}/course-ref`
      );
      return data;
    },
  },
  getters: {},
});

export default userGoodsStore;
