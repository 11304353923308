import { defineStore } from 'pinia';
import http from 'axios';

interface IDataTransfer {
  handoverId: number;
  receiveId: number;
  handoverTypeEnum: string;
}

const useOceanStore = defineStore({
  id: 'ocean',
  state: () => ({
    chanelList: [],
  }),
  actions: {
    async addCustom(form: any) {
      const data = await http.post('/customer-service/customers', form);
      return data;
    },
    async getCustoms(form: any) {
      const { data } = await http.get('/customer-service/customers', form);
      return data;
    },
    async getCustomChannels() {
      const { data } = await http.get('/customer-service/customers/channels');
      this.chanelList = data;
      return data;
    },
    async editTag(params: any) {
      const data = await http.put('/customer-service/tags', params);
      return data;
    },
    async editCustom(params: any) {
      const data = await http.put('/customer-service/customers', params);
      return data;
    },
    async getTodayStatistics(params: any) {
      const data = await http.get('/customer-service/customers/count/day', {
        params,
      });
      return data;
    },
    async getCustomPhone(id: any) {
      const data = await http.get(`/customer-service/customers/${id}/phone`);
      return data;
    },
    async removeFlow(id: any) {
      const data = await http.put(`/customer-service/customers/${id}`);
      return data;
    },
    async removeTag(id: any) {
      const data = await http.put(`/customer-service/tags/${id}`);
      return data;
    },
    async dataTransfer(params: IDataTransfer) {
      const data = await http.put(
        `/customer-service/customers/${params.handoverId}/transfer/${params.receiveId}?handoverTypeEnum=${params.handoverTypeEnum}`
      );
      return data;
    },
    // 查看顾问当前工具号二维码
    async getCustomerToolImg(params: any) {
      const data = await http.get(
        `/customer-service/customers/${params.customerId}/adviser-qrcode`
      );
      return data;
    },
    // 查询专属公众号二维码
    async getOwnerWxCode(params: any) {
      const data = http.get(
        `/customer-service/customers/${params.customerId}/official-qrcode`
      );
      return data;
    },
    /** 量线索 */
    // 查询量线索
    async getFlowClueData(params: any) {
      const data = http.get(`/customer-service/customers/clue`, { params });
      return data;
    },
    // 新增量线索
    async addFlowClueData(params: any) {
      const data = http.post(`/customer-service/customers/clue`, params);
      return data;
    },
    // 编辑量线索
    async editFlowClueData(id: number, params: any) {
      const data = await http.put(
        `/customer-service/customers/clue/${id}`,
        params
      );
      return data;
    },
    // 删除量线索
    async delFlowClueData(params: any) {
      const data = http.delete(`/customer-service/customers/clue/${params.id}`);
      return data;
    },
    // 修改是否添加分配
    async changeAllotStatus(params: any) {
      const data = await http.put(
        `/customer-service/customers/clue/${params.id}/status?allocation=${params.allocation}`
      );
      return data;
    },
    // 下载导入模板
    async downloadTemplate() {
      const data = await http.get(
        `/customer-service/customers/clue/import/template`,
        {
          responseType: 'blob',
        }
      );
      return data;
    },
    // 批量导入数据
    async importFileData(params: any) {
      const data = await http.post(
        `/customer-service/customers/clue/import`,
        params,
        // @ts-ignore
        { 'Content-Type': 'multipart/form-data' }
      );
      return data;
    },
  },
  getters: {
    chanelListGetters(state) {
      return state.chanelList;
    },
  },
});
export default useOceanStore;
