import axios from 'axios';
import type { AxiosRequestConfig, AxiosResponse } from 'axios';
import '@arco-design/web-vue/es/message/style/index';
import '@arco-design/web-vue/es/modal/style/index';
import { Message } from '@arco-design/web-vue';
// import { useUserStore } from '@/store';
import { getToken } from '@/utils/auth';
import * as dd from 'dingtalk-jsapi';

export interface HttpResponse<T = unknown> {
  status: number;
  msg: string;
  code: number;
  data: T;
}

if (import.meta.env.VITE_API_BASE_URL) {
  axios.defaults.baseURL = import.meta.env.VITE_API_BASE_URL;
}

axios.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    // @ts-ignore
    config.headers.Authorization = getToken();
    return config;
  },
  (error) => {
    // do something
    return Promise.reject(error);
  }
);
// add response interceptors
axios.interceptors.response.use(
  (response: AxiosResponse<HttpResponse>) => {
    return response;
  },
  (error) => {
    Message.error({
      content:
        error?.response?.data ||
        error?.response?.data?.message ||
        '请求失败，请重试',
      duration: 5 * 1000,
    });
    /**
     * 去除401状态码的判断
     * 1. 未登录状态无需清除本地缓存
     * 2. 登录状态401状态是因接口设置调用权限返回，无需清除token、退出登录等操作
     */
    if ([403].includes(error?.response?.status)) {
      // 当进入登录页面时code中参数不存在时，请求企业配置接口为401状态，需要判断如果接口触发401并且页面在登录页面时，不做额外跳转逻辑，如果在系统内部页面中触发接口401，需要跳转至相关登录页面
      if (!['/oauth', '/oauth-code'].includes(window.location.pathname)) {
        const code = localStorage.getItem('orgCode');
        if (dd.env.platform === 'notInDingTalk') {
          window.location.replace(`/oauth?code=${code}`);
        } else {
          window.location.replace(`/oauth-code?code=${code}&corpId=$CORPID$`);
        }
        localStorage.clear();
        return false;
      }
      return false;
    }
    return Promise.reject(error);
  }
);

export default axios;
