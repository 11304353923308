import { DEFAULT_LAYOUT } from '../base';
import { AppRouteRecordRaw } from '../types';

const Ocean: AppRouteRecordRaw = {
  path: '/ocean',
  name: 'ocean',
  component: DEFAULT_LAYOUT,
  meta: {
    name: '流量',
    locale: 'menu.ocean',
    requiresAuth: true,
    icon: 'icon-drag-dot-vertical',
    order: 0,
  },
  children: [
    {
      path: 'sale',
      name: 'Sale',
      component: () => import('@/views/ocean/sale/index.vue'),
      meta: {
        name: '量列表',
        locale: 'menu.ocean.sale',
        requiresAuth: true,
        roles: ['*'],
      },
    },
    {
      path: 'spread',
      name: 'Spread',
      component: () => import('@/views/ocean/spread/index.vue'),
      meta: {
        name: '量录入',
        locale: 'menu.ocean.spread',
        requiresAuth: true,
        roles: ['*'],
      },
    },
    {
      path: 'redar',
      name: 'Redar',
      component: () => import('@/views/ocean/radar/index.vue'),
      meta: {
        name: '互动雷达',
        locale: 'menu.ocean.radar',
        requiresAuth: true,
        roles: ['*'],
      },
    },
    {
      path: 'clue',
      name: 'Clue',
      component: () => import('@/views/ocean/clue/index.vue'),
      meta: {
        name: '量线索',
        locale: 'menu.ocean.clue',
        requiresAuth: true,
        roles: ['*'],
      },
    },
  ],
};

export default Ocean;
