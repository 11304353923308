import { DEFAULT_LAYOUT } from '../base';
import { AppRouteRecordRaw } from '../types';

const Achieve: AppRouteRecordRaw = {
  path: '/xuefu-service',
  name: 'xuefuService',
  component: DEFAULT_LAYOUT,
  meta: {
    locale: 'menu.xuefu',
    name: '教务',
    requiresAuth: true,
    icon: 'icon-stamp',
    order: 0,
  },
  children: [
    {
      path: 'divide/binding-clazz',
      name: 'bindingClazz',
      component: () => import('@/views/course-service/index.vue'),
      meta: {
        locale: 'menu.xuefu.bindingClazz',
        name: '分班列表',
        requiresAuth: true,
        roles: ['*'],
      },
    },
    {
      path: 'clazz/clazz-list',
      name: 'clazzList',
      component: () => import('@/views/xuefu-service/index.vue'),
      meta: {
        locale: 'menu.xuefu.clazzList',
        name: '班级列表',
        requiresAuth: true,
        roles: ['*'],
      },
    },
    {
      path: 'clazz/students',
      name: 'students',
      component: () => import('@/views/xuefu-service/index.vue'),
      meta: {
        hideInMenu: true,
        locale: 'menu.xuefu.students',
        name: '学员列表页面',
        requiresAuth: true,
        roles: ['*'],
      },
    },
    {
      path: 'clazz/learn-path',
      name: 'learnPath',
      component: () => import('@/views/xuefu-service/index.vue'),
      meta: {
        hideInMenu: true,
        locale: 'menu.xuefu.learnPath',
        name: '学习轨迹页面',
        requiresAuth: true,
        roles: ['*'],
      },
    },
  ],
};

export default Achieve;
