import localeMessageBox from '@/components/message-box/locale/zh-CN';
import localeLogin from '@/views/login/locale/zh-CN';

import localeWorkplace from '@/views/dashboard/workplace/locale/zh-CN';
import localOcean from '@/views/ocean/locale/zh-CN';
import localOrder from '@/views/order/locale/zh-CN';
import localMySettings from '@/views/settings/locale/zh-CN';
import localGoods from '@/views/goods/locale/zh-CN';
import localCourse from '@/views/course-service/locale/zh-CN';

import localeSettings from './zh-CN/settings';

export default {
  'menu.dashboard': '首页',
  'menu.server.dashboard': '仪表盘-服务端',
  'menu.server.workplace': '工作台-服务端',
  'menu.server.monitor': '实时监控-服务端',
  'menu.list': '列表页',
  'menu.result': '结果页',
  'menu.exception': '异常页',
  'menu.form': '表单页',
  'menu.profile': '详情页',
  'menu.visualization': '数据可视化',
  'menu.user': '个人中心',
  'menu.arcoWebsite': 'Arco Design',
  'menu.faq': '常见问题',
  'navbar.docs': '文档中心',
  'navbar.action.locale': '切换为中文',

  'menu.ocean': '流量',
  'menu.order': '订单',
  'menu.goods': '商品',
  'menu.settings': '设置',
  'menu.doc': '文档管理',
  'menu.framework': '架构管理',
  ...localOcean,
  ...localOrder,
  ...localMySettings,
  ...localGoods,

  ...localeSettings,
  ...localeMessageBox,
  ...localeLogin,
  ...localeWorkplace,
  ...localCourse,

  // 量-子应用
  'menu.flow': '量配置',
  'menu.flow.trialList': '推量组',
  'menu.flow.reception': '接量组',

  // 教学子应用
  'menu.course.category': '学院列表',
  'menu.course.curriculum': '试学课-章节列表',
  'menu.course.calendar': '排课日历',
  'menu.course.stages': '排期管理',
  'menu.course.formalList': '正式课列表',
  'menu.course.channels': '正式课-章节列表',
  'menu.course.manage': '课程管理',

  // 统计-子应用
  'menu.statistics': '数据统计',
  'menu.achieve.progress': '业绩达成进度',
  'menu.achieve.stage': '阶段业绩情况',
  'menu.achieve.goods': '各商品业绩统计',
  'menu.achieve.consultant': '梯队顾问统计',
  'menu.achieve.tencent': '直播用量统计',
  'menu.achieve.clue': '入量趋势',
  'menu.achieve.staff': '员工薪资统计',

  'menu.statistics.flow': '量统计',
  'menu.statistics.promotion': '推广统计',
  'menu.statistics.adviser': '顾问统计',
  'menu.statistics.flowConversion': '量转化情况',

  'menu.statistics.order': '订单统计',
  'menu.statistics.orderComplete': '订单完成情况',
  'menu.statistics.orderConversion': '订单转化情况',

  // 教务-子应用
  'menu.xuefu': '教务',
  'menu.xuefu.divide': '分班管理',
  'menu.xuefu.bindingClazz': '分班列表',
  'menu.xuefu.clazz': '班级管理',
  'menu.xuefu.clazzList': '班级列表',
  'menu.xuefu.students': '学员列表',
  'menu.xuefu.learnPath': '学习轨迹',

  // 部门-子应用
  'menu.staff.employee': '员工',
  'menu.staff.role': '角色',
  'menu.staff.allot': '分配用户',
  'menu.staff.dept': '部门',
};
