import { DEFAULT_LAYOUT } from '../base';
import { AppRouteRecordRaw } from '../types';

const SETTINGS: AppRouteRecordRaw = {
  path: '/',
  name: 'settings',
  component: DEFAULT_LAYOUT,
  meta: {
    name: '设置',
    locale: 'menu.settings',
    requiresAuth: true,
    icon: 'icon-settings',
    order: 7,
  },
  children: [
    {
      path: 'settings/data-move',
      name: 'DataMove',
      component: () => import('@/views/settings/data-move/index.vue'),
      meta: {
        name: '数据转移',
        locale: 'menu.settings.datamove',
        requiresAuth: true,
        roles: ['*'],
      },
    },
    {
      path: 'staff-service/user/user-list',
      name: 'staffEmployee',
      component: () => import('@/views/staff-service/index.vue'),
      meta: {
        name: '员工',
        locale: 'menu.staff.employee',
        requiresAuth: true,
        roles: ['*'],
      },
    },
    {
      path: 'staff-service/user/role-list',
      name: 'staffRoleList',
      component: () => import('@/views/staff-service/index.vue'),
      meta: {
        name: '角色',
        locale: 'menu.staff.role',
        requiresAuth: true,
        roles: ['*'],
      },
    },
    {
      path: 'staff-service/user/allocated-user',
      name: 'allocatedUser',
      component: () => import('@/views/staff-service/index.vue'),
      meta: {
        name: '分配用户',
        locale: 'menu.staff.allot',
        requiresAuth: true,
        roles: ['*'],
      },
    },
    {
      path: 'staff-service/user/dept-list',
      name: 'staffDeptList',
      component: () => import('@/views/staff-service/index.vue'),
      meta: {
        name: '部门',
        locale: 'menu.staff.dept',
        requiresAuth: true,
        roles: ['*'],
      },
    },
    // {
    //   path: 'role',
    //   name: 'Role',
    //   component: () => import('@/views/settings/role/index.vue'),
    //   meta: {
    //     name: '角色和菜单',
    //     locale: 'menu.settings.role',
    //     requiresAuth: true,
    //     roles: ['*'],
    //   },
    // },
    // {
    //   path: 'staff',
    //   name: 'Staff',
    //   component: () => import('@/views/settings/staff/index.vue'),
    //   meta: {
    //     name: '用户和部门',
    //     locale: 'menu.settings.staff',
    //     requiresAuth: true,
    //     roles: ['*'],
    //   },
    // },
    {
      path: 'settings/oceanTags',
      name: 'OceanTags',
      component: () => import('@/views/settings/ocean-tags/index.vue'),
      meta: {
        name: '标签',
        locale: 'menu.settings.oceantags',
        requiresAuth: true,
        roles: ['*'],
      },
    },
    {
      path: 'settings/tool-account',
      name: 'ToolAccount',
      component: () => import('@/views/settings/tool-account/index.vue'),
      meta: {
        name: '工具号',
        locale: 'menu.settings.toolnumber',
        requiresAuth: true,
        roles: ['*'],
      },
    },
    {
      path: 'settings/business',
      name: 'Business',
      component: () => import('@/views/settings/business/index.vue'),
      meta: {
        name: '企业',
        locale: 'menu.settings.business',
        requiresAuth: true,
        roles: ['*'],
      },
    },
    {
      path: 'settings/list',
      name: 'ProtocolList',
      component: () => import('@/views/settings/protocol/list/index.vue'),
      meta: {
        name: '协议',
        locale: 'menu.settings.list',
        requiresAuth: true,
        roles: ['*'],
      },
    },
    {
      path: 'settings/add',
      name: 'ProtocolAdd',
      component: () => import('@/views/settings/protocol/add/index.vue'),
      meta: {
        name: '添加协议页面',
        locale: 'menu.settings.add',
        hideInMenu: true,
        requiresAuth: true,
        roles: ['*'],
      },
    },
    {
      path: 'settings/help',
      name: 'help',
      component: () => import('@/views/settings/help/index.vue'),
      meta: {
        name: '帮助',
        locale: 'menu.settings.help',
        hideInMenu: true,
        requiresAuth: true,
        roles: ['*'],
      },
    },
  ],
};

export default SETTINGS;
