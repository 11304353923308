export default {
  'menu.settings.oceantags': '标签',
  'menu.settings.system': '系统配置',
  'menu.settings.toolnumber': '工具号',
  'menu.settings.business': '企业',
  'menu.settings.datamove': '数据转移',
  'menu.settings.role': '角色和权限',
  'menu.settings.staff': '用户和部门',
  'menu.settings.list': '协议',
  'menu.settings.add': '创建协议',
  'menu.settings.help': '帮助',
};
