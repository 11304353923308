import axios from 'axios';
import { EnterpriseState } from '@/store/modules/enterprise/types';

// 查询
export const getEnterpriseInfo = () =>
  axios.get<EnterpriseState>('/user-service/enterprises');

// 编辑
export const editEnterpriseInfo = (data: EnterpriseState) =>
  axios.put<EnterpriseState>('/user-service/enterprises', data);
