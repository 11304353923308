import { defineStore } from 'pinia';
import http from 'axios';

const useMenuStore = defineStore({
  id: 'menu',
  state: () => ({}),
  actions: {
    async getMenuListCheck(params?: any) {
      const data = await http.get('/user-service/menu/list/check', {
        params,
      });
      return data;
    },
    async getMenuList() {
      const data = await http.get(`/staff-service/menu/treeSelect`);
      return data;
    },
    async saveRoleWithPermission(id: any, params: any) {
      const data = await http.post(
        `/user-service/menu/relation/menu/${id}`,
        params
      );
      return data;
    },
  },
});
export default useMenuStore;
