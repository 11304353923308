import { defineStore } from 'pinia';
import http from 'axios';

const useOauthStore = defineStore({
  id: 'oauth',
  state: () => ({}),
  actions: {
    async getOauthLink(params?: any) {
      const data = await http.get('/oauth-service/oauth/tripartite/address', {
        params,
      });
      return data;
    },
    async getOauthCred(params: any) {
      const data = await http.post('/oauth-service/oauth/login', params);
      return data;
    },
    // 获取七牛云上传凭证
    async getQiniuAuth() {
      const data = await http.get('/oauth-service/authentication/voucher');
      return data;
    },
    // 获取企业基础信息
    async getEnterprisesByCode(code: string) {
      const data = await http.get(`/user-service/enterprises/code/${code}`);
      return data;
    },
  },
});
export default useOauthStore;
