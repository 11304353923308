export const WHITE_LIST = [
  { name: 'notFound', children: [] },
  { name: 'login', children: [] },
  { name: 'oauth', children: [] },
  { name: 'callback', children: [] },
  { name: 'oauthCode', children: [] },
  { name: 'GoodsAdd', children: [] },
  { name: 'StagesManager', children: [] },
  { name: 'TrialCurriculumList', children: [] },
  { name: 'ProtocolAdd', children: [] },
  { name: 'learnPath', children: [] },
  { name: 'students', children: [] },
  { name: 'StagesManager', children: [] },
  { name: 'ChannelManager', children: [] },
  { name: 'allocatedUser', children: [] },
];

export const NOT_FOUND = {
  name: 'notFound',
};

export const REDIRECT_ROUTE_NAME = 'Redirect';

export const DEFAULT_ROUTE_NAME = 'Workplace';

export const DEFAULT_ROUTE = {
  title: 'menu.dashboard.workplace',
  name: DEFAULT_ROUTE_NAME,
  fullPath: '/dashboard/workplace',
};
