import { DEFAULT_LAYOUT } from '../base';
import { AppRouteRecordRaw } from '../types';

const Achieve: AppRouteRecordRaw = {
  path: '/statistics-service',
  name: 'achieveStatistics',
  component: DEFAULT_LAYOUT,
  meta: {
    locale: 'menu.statistics',
    name: '数据统计',
    requiresAuth: true,
    icon: 'icon-computer',
    order: 0,
  },
  children: [
    {
      path: 'order-statistics/order-complete',
      name: 'orderComplete',
      component: () => import('@/views/course-service/index.vue'),
      meta: {
        locale: 'menu.statistics.orderComplete',
        name: '订单完成情况',
        requiresAuth: true,
        roles: ['*'],
      },
    },
    {
      path: 'order-statistics/order-conversion',
      name: 'orderConversion',
      component: () => import('@/views/course-service/index.vue'),
      meta: {
        locale: 'menu.statistics.orderConversion',
        name: '订单转化情况',
        requiresAuth: true,
        roles: ['*'],
      },
    },
    {
      path: 'flow-statistics/promotion-statistics',
      name: 'promotionStatistics',
      component: () => import('@/views/course-service/index.vue'),
      meta: {
        locale: 'menu.statistics.promotion',
        name: '推广统计',
        requiresAuth: true,
        roles: ['*'],
      },
    },
    {
      path: 'flow-statistics/adviser-statistics',
      name: 'adviserStatistics',
      component: () => import('@/views/course-service/index.vue'),
      meta: {
        locale: 'menu.statistics.adviser',
        name: '顾问统计',
        requiresAuth: true,
        roles: ['*'],
      },
    },
    {
      path: 'flow-statistics/flow-conversion',
      name: 'flowConversion',
      component: () => import('@/views/course-service/index.vue'),
      meta: {
        locale: 'menu.statistics.flowConversion',
        name: '量转化统计',
        requiresAuth: true,
        roles: ['*'],
      },
    },
    {
      path: 'achieve-statistics/achieve-progress',
      name: 'achieveProgress',
      component: () => import('@/views/course-service/index.vue'),
      meta: {
        name: '业绩达成进度',
        locale: 'menu.achieve.progress',
        requiresAuth: true,
        roles: ['*'],
      },
    },
    {
      path: 'achieve-statistics/achieve-stage',
      name: 'achieveStage',
      component: () => import('@/views/course-service/index.vue'),
      meta: {
        name: '阶段业绩情况',
        locale: 'menu.achieve.stage',
        requiresAuth: true,
        roles: ['*'],
      },
    },
    {
      path: 'achieve-statistics/achieve-goods',
      name: 'achieveGoods',
      component: () => import('@/views/course-service/index.vue'),
      meta: {
        name: '各商品业绩统计',
        locale: 'menu.achieve.goods',
        requiresAuth: true,
        roles: ['*'],
      },
    },
    {
      path: 'achieve-statistics/echelon-consultant',
      name: 'echelonConsultant',
      component: () => import('@/views/course-service/index.vue'),
      meta: {
        locale: 'menu.achieve.consultant',
        name: '梯队顾问统计',
        requiresAuth: true,
        roles: ['*'],
      },
    },
    {
      path: 'tencent/tencent-flux',
      name: 'tencentFlux',
      component: () => import('@/views/course-service/index.vue'),
      meta: {
        locale: 'menu.achieve.tencent',
        name: '直播用量统计',
        requiresAuth: true,
        roles: ['*'],
      },
    },
    {
      path: 'flow-statistics/flow-clue',
      name: 'flowClue',
      component: () => import('@/views/course-service/index.vue'),
      meta: {
        locale: 'menu.achieve.clue',
        name: '入量线索趋势',
        requiresAuth: true,
        roles: ['*'],
      },
    },
    {
      path: 'achieve-statistics/staff-statistics',
      name: 'staffSalary',
      component: () => import('@/views/course-service/index.vue'),
      meta: {
        locale: 'menu.achieve.staff',
        name: '员工薪资统计',
        requiresAuth: true,
        roles: ['*'],
      },
    },
  ],
};

export default Achieve;
