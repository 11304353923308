import http from 'axios';
import { defineStore } from 'pinia';

const useCollegeStore = defineStore('useCollegeStore', {
  state() {
    return {};
  },
  actions: {
    // 学院
    async getCollegeList() {
      const data = await http.get('/trial-course-service/category/list');
      return data;
    },
  },
  getters: {},
});

export default useCollegeStore;
