import http from 'axios';
import { defineStore } from 'pinia';

const useTrialCurriculumStore = defineStore('useTrialCurriculumStore', {
  state() {
    return {};
  },
  actions: {
    async getTrialCourseStatistics(params: {
      page: number;
      size: number;
      account: string;
      courseId: string;
      curriculumId: string;
      endTime: number;
      startTime: number;
    }) {
      const data = await http.get(`/trial-course-service/statistics`, {
        params,
      });
      return data;
    },
    // 查询课程列表
    async getTrialCourseList(params: {
      page: number;
      size: number;
      name?: string;
      collegeId?: string;
    }) {
      const data = await http.get(`/trial-course-service/trial-course/list`, {
        params,
      });
      return data;
    },
    // 查询章节列表
    async getTrialCurriculumList(params: object) {
      const data = await http.get(
        '/trial-course-service/trial-curriculum/list',
        { params }
      );
      return data;
    },
    // 查询章节列表
    async getCalendar(params: object) {
      const data = await http.get(
        '/trial-course-service/trial-curriculum/calendar',
        { params }
      );
      return data;
    },
    // 查询章节详情
    async getTrialCurriculumInfo(params: any) {
      const data = await http.get(
        `/course-service/trial-curriculum/info/${params.id}`
      );
      return data;
    },
    // 新增章节
    async addTrialCurriculum(params: object) {
      const data = await http.post(
        '/trial-course-service/trial-curriculum/add',
        params
      );
      return data;
    },
    // 修改章节
    async updateTrialCurriculum(params: object) {
      const data = await http.put(
        '/trial-course-service/trial-curriculum/edit',
        params
      );
      return data;
    },
    // 删除章节
    async deleteTrialCurriculum(params: { id: string }) {
      const data = await http.put(
        `/course-service/trial-curriculum/delete/${params.id}`
      );
      return data;
    },
    // 更改章节状态
    async updateTrialCurriculumStatus(params: { id: string }) {
      const data = await http.put(
        `/course-service/trial-curriculum/status/${params.id}`
      );
      return data;
    },
    // 根据量账号查询用户听课数据-保利威
    async getPolyvStatisticsData(params: any) {
      const data = await http.get('/wanzhou-service/polyv/statistics', {
        params,
      });
      return data;
    },
    // 生成账号密码
    async generateAccountPwd(params: any) {
      const data = await http.get('/wanzhou-service/polyv/access', { params });
      return data;
    },
    // 查询总计听课数据统计
    async getStatisticsAssemble(params: any) {
      const data = await http.get(`/trial-course-service/statistics/assemble`, {
        params,
      });
      return data;
    },
    // 手动绑定量账号、顾问
    async bindFlowAccount(params: any) {
      const data = await http.post(`/trial-course-service/bind`, params);
      return data;
    },
    // 查询用户发言记录
    async getSpeakRecordData(params: any) {
      const data = await http.get(`/trial-course-service/speck`, {
        params,
      });
      return data;
    },
    // 查询进出直播间记录
    async getEnterLivingRoomRecord(params: any) {
      const data = await http.get(`/trial-course-service/statistics`, {
        params,
      });
      return data;
    },
  },
  getters: {},
});

export default useTrialCurriculumStore;
