import { createPinia } from 'pinia';
import useAppStore from './modules/app';
import useUserStore from './modules/user';
import useTabBarStore from './modules/tab-bar';
import userGoodsStore from './modules/goods';
import useOrderStore from './modules/order';
import useOceanStore from './modules/ocean';
import useSettingsStore from './modules/settings';
import useFrameworkStore from './modules/framework';
import useMenuStore from './modules/menu';
import useOauthStore from './modules/oauth';
import useEnterpriseStore from './modules/enterprise';
import useDashboardStore from './modules/dashboard';
import useTrialCurriculumStore from './modules/trial/trialcurriculum';
import useCollegeStore from './modules/college';

const pinia = createPinia();

export {
  useAppStore,
  useUserStore,
  useTabBarStore,
  userGoodsStore,
  useOrderStore,
  useOceanStore,
  useSettingsStore,
  useFrameworkStore,
  useMenuStore,
  useOauthStore,
  useEnterpriseStore,
  useDashboardStore,
  useTrialCurriculumStore,
  useCollegeStore,
};
export default pinia;
