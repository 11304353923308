// import http from 'axios';

export default async () => {
  // const { data } = await http.get(
  //   `${import.meta.env.VITE_MICRO_APP_URL}/micro-app.json`
  // );
  const data = [
    {
      name: 'teach-service',
      entry: import.meta.env.VITE_TEACH_URL,
      activeRule: '/teach-service',
      container: '#app-wrap',
    },
    {
      name: 'flow-service',
      entry: import.meta.env.VITE_FLOW_URL,
      activeRule: '/flow-service',
      container: '#app-wrap',
    },
    {
      name: 'statistics-service',
      entry: import.meta.env.VITE_STATISTICS_URL,
      activeRule: '/statistics-service',
      container: '#app-wrap',
    },
    {
      name: 'xuefu-service',
      entry: import.meta.env.VITE_XUEFU_URL,
      activeRule: '/xuefu-service',
      container: '#app-wrap',
    },
    {
      name: 'staff-service',
      entry: import.meta.env.VITE_STAFF_URL,
      activeRule: '/staff-service',
      container: '#app-wrap',
    },
  ];
  return data;
};
