import { defineStore } from 'pinia';
import { queryStageCountPrice } from '@/api/dashboard';
import { DashboardState } from './types';

const useDashboardStore = defineStore('dashboard', {
  state: (): DashboardState => ({
    sevenPrice: undefined,
    yearPrice: undefined,
    sevenWholePrice: undefined,
  }),
  getters: {
    stageCountPrice(state: DashboardState): DashboardState {
      return { ...state };
    },
  },
  actions: {
    setStageCountPrice(partial: Partial<DashboardState>) {
      this.$patch(partial);
    },
    async getStageCountPrice() {
      try {
        const { data } = await queryStageCountPrice();
        this.setStageCountPrice(data);
      } catch (error) {
        //
      }
    },
  },
});

export default useDashboardStore;
