import { defineStore } from 'pinia';

import http from 'axios';
import {
  login as userLogin,
  logout as userLogout,
  LoginData,
} from '@/api/user';
import { setToken, clearToken } from '@/utils/auth';
import { removeRouteListener } from '@/utils/route-listener';
import { UserState } from './types';
import useAppStore from '../app';

const useUserStore = defineStore('user', {
  state: (): UserState => ({
    name: undefined,
    avatar: undefined,
    job: undefined,
    organization: undefined,
    location: undefined,
    email: undefined,
    introduction: undefined,
    personalWebsite: undefined,
    jobName: undefined,
    organizationName: undefined,
    locationName: undefined,
    phone: undefined,
    registrationDate: undefined,
    accountId: undefined,
    certification: undefined,
    role: '',
    buttonRole: undefined,
    // @ts-ignore
    userInfo: JSON.parse(localStorage.getItem('userInfo')),
  }),

  getters: {
    userInfo(state: UserState): UserState {
      return { ...state };
    },
  },

  actions: {
    // 根据用户名查找用户信息
    async getUserList(params?: any) {
      const data = await http.get('/user-service/user/list', {
        params,
      });
      return data;
    },
    async getStaffUserList(params?: any) {
      const data = await http.get('/staff-service/user/list', {
        params,
      });
      return data;
    },
    // 更新员工量分配权重
    async updateUserWeight(params?: any) {
      const data = await http.post('/user-service/user/weight/update', params);
      return data;
    },
    // 获取用户信息
    async getUserInfo() {
      const data = await http.get('/staff-service/user/info/header');
      return data;
    },
    switchRoles() {
      return new Promise((resolve) => {
        this.role = this.role === 'user' ? 'admin' : 'user';
        resolve(this.role);
      });
    },
    // Set user's information
    setInfo(partial: Partial<UserState>) {
      this.$patch(partial);
    },

    // Reset user's information
    resetInfo() {
      this.$reset();
    },

    // Get user's information
    // async info() {
    //   const res = await getUserInfo();

    //   this.setInfo(res.data);
    // },

    // Login
    async login(loginForm: LoginData) {
      try {
        const res = await userLogin(loginForm);
        setToken(res.data.token);
      } catch (err) {
        clearToken();
        throw err;
      }
    },
    logoutCallBack() {
      const appStore = useAppStore();
      this.resetInfo();
      clearToken();
      removeRouteListener();
      appStore.clearServerMenu();
    },
    // Logout
    async logout() {
      try {
        await userLogout();
      } finally {
        this.logoutCallBack();
      }
    },
    async getPaymentChannel(params: any) {
      const data = await http.get(
        '/user-service/enterprises/paymentChannel',
        params
      );
      return data;
    },
  },
});

export default useUserStore;
