import { defineStore } from 'pinia';
import {
  getEnterpriseInfo,
  editEnterpriseInfo,
} from '@/api/settings/enterprise';
import type { EnterpriseState } from './types';

const useEnterpriseStore = defineStore('enterprise', {
  state: (): { enterpriseInfo: EnterpriseState } => ({
    enterpriseInfo: {
      name: undefined,
      logo: undefined,
    },
  }),
  getters: {
    getInfo(state): EnterpriseState {
      return state.enterpriseInfo;
    },
  },
  actions: {
    // 获取企业信息
    async getEnterpriseInfo() {
      const { data } = await getEnterpriseInfo();
      this.enterpriseInfo = data;
    },
    async editorEnterpriseInfo(res: EnterpriseState) {
      const data = await editEnterpriseInfo(res);
      return data;
    },
  },
});
export default useEnterpriseStore;
