import { DEFAULT_LAYOUT } from '../base';
import { AppRouteRecordRaw } from '../types';

const Ocean: AppRouteRecordRaw = {
  path: '/goods',
  name: 'Goods',
  component: DEFAULT_LAYOUT,
  meta: {
    name: '商品',
    locale: 'menu.goods',
    requiresAuth: true,
    icon: 'icon-gift',
    order: 0,
  },
  children: [
    {
      path: 'list',
      name: 'GoodsList',
      component: () => import('@/views/goods/list/index.vue'),
      meta: {
        name: '商品列表',
        locale: 'menu.goods.list',
        requiresAuth: true,
        roles: ['*'],
      },
    },
    {
      path: 'add',
      name: 'GoodsAdd',
      component: () => import('@/views/goods/add/index.vue'),
      meta: {
        name: '添加商品页面',
        locale: 'menu.goods.add',
        requiresAuth: true,
        hideInMenu: true,
        roles: ['*'],
      },
    },
  ],
};

export default Ocean;
