import { App } from 'vue';
import permission from './permission';
import permissionm from './button-permisson';

export default {
  install(Vue: App) {
    Vue.directive('permission', permission);
    Vue.directive('permissionm', permissionm);
  },
};
