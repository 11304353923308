import { MyServerMenu } from '@/store/modules/app/types';
import { RouteRecordNormalized } from 'vue-router';
import { appRoutes, appExternalRoutes } from '../routes';

const mixinRoutes = [...appRoutes, ...appExternalRoutes];

const appClientMenus = mixinRoutes.map((el) => {
  const { name, path, meta, redirect, children } = el;
  return {
    name,
    path,
    meta,
    redirect,
    children,
  };
});
// TODO 待删除  方法错误
export const createServerMenus = (
  _appClientMenus: RouteRecordNormalized[],
  _serverMenus: MyServerMenu[]
): RouteRecordNormalized[] => {
  const menu: RouteRecordNormalized[] = [];
  _appClientMenus?.forEach((clientMenu) => {
    _serverMenus?.forEach((serverMenu: MyServerMenu) => {
      if (clientMenu.meta.name === serverMenu.name) {
        if (serverMenu.children && serverMenu.children.length > 0) {
          clientMenu.children = createServerMenus(
            clientMenu.children as RouteRecordNormalized[],
            serverMenu.children
          );
        }
      }
    });
    menu.push(clientMenu);
  });

  return menu as unknown as RouteRecordNormalized[];
};

/**
 * 返回组装好的serverMenu数据
 * @param _appClientMenus 本地路由
 * @param _serverMenus 服务端路由
 * @returns
 */
export const createServerMenus1 = (
  _appClientMenus: any,
  _serverMenus: any,
  _newMenus: any
) => {
  _appClientMenus?.forEach((clientMenu: any) => {
    _serverMenus?.forEach((serverMenu: any) => {
      if (serverMenu.label === clientMenu.meta.name) {
        const copyClientMenu = { ...clientMenu, children: [] };
        const newMenu: any = {
          ...copyClientMenu,
          children: [],
        };

        if (serverMenu.children && serverMenu.children.length > 0) {
          createServerMenus1(
            clientMenu.children,
            serverMenu.children,
            newMenu.children
          );
        } else {
          newMenu.children = undefined;
        }
        _newMenus.push(newMenu);
      }
    });
  });
  return _newMenus;
};

// 获取用户按钮权限
const buttonMenu: MyServerMenu[] = [];
export const createButtonRole = (
  _serverMenus: MyServerMenu[]
): MyServerMenu[] => {
  _serverMenus.forEach((serverMenu) => {
    if (serverMenu.children && serverMenu.children.length > 0) {
      createButtonRole(serverMenu.children);
    }
    if (serverMenu.buttonType === 'F') {
      buttonMenu.push(serverMenu);
    }
  });
  return buttonMenu;
};

export default appClientMenus;
